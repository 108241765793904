import React from "react";

import styled from "styled-components";
import Container from "@components/Container";
import Post from "@components/Post";

import media from "@styles/media";

interface PostsViewProps {
  posts: any;
}

const PostsViewWrapper = styled.div`
  padding: 50px 0;
  padding: 0 80px;
  max-width: 1520px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.toTablet`
    padding: 0 40px;
  `}
  ${media.toTabletP`
    padding: 0 20px;
  `}
`;

const PostViewBlogs = styled.div`
  width: 60%;

  ${media.toTabletP`
    width: 100%;
  `}
`;

const PostsView: React.FC<PostsViewProps> = ({ posts }) => {
  return (
    <Container>
      <PostsViewWrapper>
        <PostViewBlogs>
          {posts.map(({ frontmatter, fields, id }: any) => {
            const slug = fields?.slug;

            return (
              <Post
                title={frontmatter?.title}
                description={frontmatter?.description}
                image={frontmatter?.main_image}
                slug={slug}
                key={id}
              />
            );
          })}
        </PostViewBlogs>
      </PostsViewWrapper>
    </Container>
  );
};

export default PostsView;
