import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface ImageProps {
  title?: string;
  alt: string;
  src: any;
}

const Image: React.FC<ImageProps> = ({ alt, title, src }) => {
  const imageData = getImage(src);

  if (imageData) {
    return <GatsbyImage image={imageData} alt={alt} title={title} />;
  }

  if (typeof src === "string") {
    return <img src={src} alt={alt} title={title} />;
  }

  return null;
};

export default Image;
